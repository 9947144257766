<template>
  <div>
    <CCard style="margin-bottom:0">
      <CCardHeader>
        <div class="card-header-actions">
          <div style="cursor:pointer" @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <my-logo/>
          </CCol>
          <CCol lg="9">
            <config/>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {freeSet} from '@coreui/icons'
import CMyLogo from "@/views/v1/components/MyLogo"
import Config from "@/views/v1/components/Config";

export default {
  name: "Config",
  freeSet,
  props: {},
  mounted() {
  },
  components: {
    "my-logo": CMyLogo,
    "config": Config,
  },
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

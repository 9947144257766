<template>
  <div>
    <CNav fill variant="tabs">
      <CNavItem v-for="(nav, index) in navs" :active="isActive(index)"><div @click="isShow(index)">{{nav}}</div></CNavItem>
    </CNav>
    <!-- お仕事設定のカード -->
    <CCard v-show="show[0]">
      <CCardBody>
        <CRow>
          <CCol col="6">
            <CSelect
              label="表示件数"
              :horizontal="{ label: 'col-sm-8', input: 'col-sm-4'}"
              size=""
              v-bind:value.sync="jobSettings.numOfListingJobs"
              :options="[1,2,3,4,5,6,7,8,9,10,15,20]"
            >
              <template v-slot:append>
                <CButton
                  style="border-radius: 0"
                  @click="saveValue(1, 'hoge')"
                  v-c-tooltip.mouseover="{content: '保存', active:false }"
                  color="secondary">
                  <CIcon size="sm" style="color:#151515" name="cil-save"/>
                </CButton>
              </template>
            </CSelect>
          </CCol>
          <CCol col="12" lg="6" style="font-weight: lighter">
            お仕事一覧画面の「全部」のタブに表示されるお仕事の数を指定します。
          </CCol>
          <CCol col="6">
            <CSelect
              label="同時並行お仕事数"
              v-bind:value.sync="jobSettings.numOfParallelJobs"
              :horizontal="{ label: 'col-sm-8', input: 'col-sm-4'}"
              size=""
              :options="[1,2,3,4,5]"
            >
              <template v-slot:append>
                <CButton
                  style="border-radius: 0"
                  v-c-tooltip.mouseover="{content: '保存', active:false }"
                  color="secondary">
                  <CIcon size="sm" style="color:#151515" name="cil-save"/>
                </CButton>
              </template>
            </CSelect>
          </CCol>
          <CCol col="12" lg="6" style="font-weight: lighter">
            <div>同時に実行できるお仕事の数を指定します。</div>
            <div>この設定値を超えると仕事に申し込みができなくなります。</div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="8" lg="6">
            <CInput
              prepend="¥"
              v-model="jobSettings.defaultJobPrice"
              :horizontal="{ label: 'col-sm-7', input: 'col-sm-5'}"
              label="お仕事単価"
            >
              <template v-slot:append>
                <CButton
                  style="border-radius: 0"
                  v-c-tooltip.mouseover="{content: '保存', active:false }"
                  color="secondary">
                  <CIcon size="sm" style="color:#151515" name="cil-save"/>
                </CButton>
              </template>
            </CInput>
          </CCol>
          <CCol col="12" lg="6" style="font-weight: lighter">
            デフォルトのお仕事の金額です。お仕事毎に修正できます。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <CInput
              type="password"
              v-model="jobSettings.lineAPIKey"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              label="LINE API KEY"
            >
              <template v-slot:append>
                <CButton
                  style="border-radius: 0"
                  v-c-tooltip.mouseover="{content: '保存', active:false }"
                  color="secondary">
                  <CIcon size="sm" style="color:#151515" name="cil-save"/>
                </CButton>
              </template>
            </CInput>
          </CCol>
          <CCol col="12" lg="6" style="font-weight: lighter">
            LINE API Key を入力いただくことでお仕事登録時など設定したタイミングでの通知が送られます。
          </CCol>
        </CRow>
        <CRow style="margin-bottom: 16px">
          <CCol lg="6">
            <CRow>
              <CCol lg="5">
                ユーザ設定
              </CCol>
              <CCol lg="7">
                <CInputCheckbox
                  label="ユーザ名の変更"
                  v-bind:checked="userPermitSettings.changeUsername"
                  v-on:update:checked="userPermitSettings.changeUsername = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="パスワードの変更"
                  v-bind:checked="userPermitSettings.changePassword"
                  v-on:update:checked="userPermitSettings.changePassword = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="アイコン画像"
                  v-bind:checked="userPermitSettings.changeAvatar"
                  v-on:update:checked="userPermitSettings.changeAvatar = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="メールアドレスの変更"
                  v-bind:checked="userPermitSettings.changeEmail"
                  v-on:update:checked="userPermitSettings.changeEmail = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="表示名の変更"
                  v-bind:checked="userPermitSettings.changeNickname"
                  v-on:update:checked="userPermitSettings.changeNickname = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="名字の変更"
                  v-bind:checked="userPermitSettings.changeFirstName"
                  v-on:update:checked="userPermitSettings.changeFirstName = $event"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="名前の変更"
                  v-bind:checked="userPermitSettings.changeLastName"
                  v-on:update:checked="userPermitSettings.changeLastName = $event"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" lg="6" style="font-weight: lighter">
            <div>ユーザが設定自由に設定できる項目を指定します。</div>
            <div>チェックがついてる項目はユーザによる変更が可能になります。</div>
          </CCol>
        </CRow>
        <!-- お仕事カテゴリの設定 -->
        <CRow>
          <CCol lg="6">
            <CRow>
              <CCol lg="5">
                お仕事カテゴリ
              </CCol>
              <CCol lg="7">
                <CInput
                  v-for="(item, index) in jobCategories"
                  size="sm"
                  :disabled="item['fid'] === 0"
                  :value="item['label']"
                  style="margin:0">
                  <template v-slot:prepend>
                    <CButton
                      v-c-tooltip.mouseover="{content: 'デフォルトにする', active:false }"
                      @click="setDefaultCategory(index)"
                      style="border-radius: 0"
                      color="secondary">
                      <CIcon
                        size="sm"
                        :style="isDefaultCategory(item)"
                        name="cil-check-circle"/>
                    </CButton>
                  </template>
                  <template v-if="item['fid'] !== 0" v-slot:append>
                    <CDropdown
                      color="secondary"
                      v-c-tooltip.mouseover="{content: 'クリックして保存か削除', active:false }"
                      size="sm"
                      addTogglerClasses="caret-btn"
                      :caret="false"
                      style="margin:0"
                    >
                      <template #toggler-content>
                        <CIcon
                          size="sm"
                          style="color:#262626"
                          name="cil-caret-right"/>
                      </template>
                      <CDropdownItem>
                        <CIcon style="margin-right: 3px" name="cil-save"/>
                        修正内容を保存</CDropdownItem>
                      <CDropdownItem>
                        <CIcon style="margin-right: 3px" name="cil-trash"/>
                        カテゴリを削除</CDropdownItem>
                    </CDropdown>
                  </template>
                </CInput>
                <CInput
                  size="sm"
                  placeholder="カテゴリを追加"
                  style="margin:0;">
                  <template v-slot:append>
                    <CButton
                      v-c-tooltip.mouseover="{content: '保存', active:false }"
                      style="border-radius: 0"
                      @click="addJobCategory('ほげ')"
                      color="secondary">
                      <CIcon size="sm" style="color:#151515" name="cil-save"/>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" lg="6" style="font-weight:lighter">
            お仕事のカテゴリを設定します。
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              style="width:100%"
              variant="outline"
              size="sm"
              color="primary"
              @click="updateJobSettings"
            >更新
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <!-- ファミリープロフィールのカード -->
    <CCard v-show="show[1]">
      <CCardBody>
        <CRow>
          <CCol lg="5">
            <CInput
              v-model="familyProfiles.nickname"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}">
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  ニックネーム<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            ご家族のニックネームを設定します。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CSelect
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              size=""
              v-bind:value.sync="familyProfiles.birthYear"
              :options="getNumArrayByString(1921, 2021)"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  お誕生年<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CSelect>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            管理者の方のお誕生年を入力してください。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CSelect
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              size=""
              v-bind:value.sync="familyProfiles.familyStructure"
              :options="familyStructureOptions"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  家族構成<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CSelect>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            ご家族構成について一番近いものを選択してください。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CInput
              v-model="familyProfiles.postalCode"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}">
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  郵便番号
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            <div>ご自宅の郵便番号を入力してください。</div>
            <div>-（ハイフン）は不要です。</div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CSelect
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              size=""
              v-bind:value.sync="familyProfiles.housingForm"
              :options="housingFormOptions"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  居住形態
                </CCol>
              </template>
            </CSelect>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            ご自宅について一番近いものをご選択ください。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CSelect
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              size=""
              v-bind:value.sync="familyProfiles.occupation"
              :options="occupationOptions"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  ご職業
                </CCol>
              </template>
            </CSelect>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            管理者様のご職業について一番近いものを選択してください。
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="5">
            <CSelect
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              size=""
              v-bind:value.sync="familyProfiles.annualIncome"
              :options="annualIncomeOptions"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  年収
                </CCol>
              </template>
            </CSelect>
          </CCol>
          <CCol col="7" style="font-weight: lighter">
            ご年収についてご選択ください。
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              @click="updateFamilyProfileSettings"
              style="width:100%"
              variant="outline"
              size="sm"
              color="primary"
            >更新
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <!-- ユーザ追加のカード -->
    <CCard v-show="show[2]">
      <CCardHeader>
        登録済みユーザ
      </CCardHeader>
      <CCardBody>
        <CListGroup>
          <CListGroupItem accent="light">
            <CRow>
              <CCol lg="3">表示名</CCol>
              <CCol lg="2">ログインID</CCol>
              <CCol lg="2">お名前</CCol>
              <CCol lg="5">メール</CCol>
            </CRow>

          </CListGroupItem>
          <CListGroupItem
            v-for="member in $store.state.your_family"
            tag="button">
            <CRow
              @click="clickMember(member)"
            >
              <CCol lg="3">
                <CIcon v-if="member['is_admin']" name="cil-check"/>
                {{member['nickname']}}</CCol>
              <CCol lg="2">{{member['username']}}</CCol>
              <CCol lg="2">{{member['first_name']}} {{member['last_name']}}</CCol>
              <CCol lg="5">{{member['mail']}}</CCol>
            </CRow>
          </CListGroupItem>
        </CListGroup>
        <CRow v-if="!showEditMember" style="margin-top: 3px">
          <CCol col="5" lg="3" class="offset-7 offset-lg-9">
            <CButton
              @click="addMember"
              style="width:100%"
              variant="outline"
              size="sm"
              color="primary"
            >ユーザを追加</CButton>
          </CCol>
        </CRow>
      </CCardBody>
      <!-- ユーザの追加or編集のカード -->
      <CCardBody v-if="showEditMember">
        <!-- アバター画像 -->
        <CRow style="margin-bottom: 15px">
          <CCol lg="3">
            <CImg
              :src="_get_avatar(editMember.id)"
              shape="rounded-circle"
              width="100%"
            />
          </CCol>
        </CRow>
        <!-- ニックネーム -->
        <CRow>
          <CCol lg="5">
            <CInput
              v-bind:value="editMember.nickname"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}">
              <template v-slot:label>
                <CCol
                  col="5" style="padding:7px 5px 7px 5px;">
                  ニックネーム<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol lg="7" col="12" style="font-weight: lighter">
            画面に表示されるニックネームです。
          </CCol>
        </CRow>
        <!-- ユーザID -->
        <CRow>
          <CCol lg="5">
            <CInput
              v-bind:value="editMember.username"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
            >
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  ユーザID<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol lg="7" col="12" style="font-weight: lighter">
            <div>ログイン時に必要となるユーザIDです。 当サイト全体で唯一になる必要があります。</div>
          </CCol>
        </CRow>
        <!-- パスワード -->
        <CRow>
          <CCol lg="5">
            <CInput
              v-bind:value="editMember.password"
              type="password"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}">
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  パスワード<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol lg="7" col="12" style="font-weight: lighter">
            ログイン時に必要となるパスワードです。
          </CCol>
          <CCol lg="5">
            <CInput
              v-bind:value="editMember.password"
              type="password"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}">
              <template v-slot:label>
                <CCol col="5" style="padding:7px 5px 7px 5px;">
                  パスワード(再)<CBadge color="warning" style="margin-left: 5px">必須</CBadge>
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol lg="7" col="12" style="font-weight: lighter">
            上で入力したパスワードと同じ値を入力してください。
          </CCol>
        </CRow>
        <!-- 氏名 -->
        <CRow>
          <CCol lg="5">
            <CRow style="margin:0 -5px 0 -5px">
              <CCol sm="5" style="padding: 7px 5px 7px 5px">
                <label>氏名</label>
              </CCol>
              <CCol sm="7" style="padding: 0 5px 0 5px">
                <CRow>
                  <CCol col="6" style="padding-right: 1px">
                    <CInput v-bind:value="editMember.first_name"/>
                  </CCol>
                  <CCol col="6" style="padding-left: 0">
                    <CInput v-bind:value="editMember.last_name"/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
          <CCol lg="7" col="12" style="font-weight: lighter">
            <div>お名前を入力してください。</div>
          </CCol>
        </CRow>
        <!-- メール -->
        <CRow>
          <CCol lg="6">
            <CInput
              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
              v-bind:value="editMember.mail"
              type="email"
              autocomplete="email"
            >
              <template v-slot:label>
                <CCol col="4" style="padding:7px 5px 7px 5px;">
                  メールアドレス
                </CCol>
              </template>
            </CInput>
          </CCol>
          <CCol lg="6" col="12" style="font-weight: lighter">
            入力いただくことでお仕事登録時などに通知を送付することができます。
          </CCol>
        </CRow>
        <!-- 管理者設定 -->
        <CRow>
          <CCol lg="5">
            <CRow>
              <CCol col="5">
                管理者設定
              </CCol>
              <CCol col="7">
                <CInputCheckbox
                  :checked="editMember.is_admin"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol lg="7" style="font-weight: lighter">
            <div>チェックを入れると管理者として設定されます。</div>
            <div>管理者になるとすべての設定変更が可能になります。</div>
          </CCol>
        </CRow>
        <!-- 登録ボタン -->
        <CRow style="margin-top: 15px">
          <CCol col="6" lg="3">
            <CButton
              @click="cancelEditMember"
              style="width:100%"
              variant="outline"
              size="sm"
              color="warning"
            >キャンセルして閉じる</CButton>
          </CCol>
          <CCol col="5" lg="3" class="offset-1 offset-lg-6">
            <CButton
              style="width:100%"
              variant="outline"
              size="sm"
              color="primary"
            >{{registerButtonLabel}}</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!--  通知配信設定のカード -->
    <CCard v-show="show[3]">
      <CCardBody>
        <CRow style="margin-bottom: 16px">
          <CCol lg="7">
            <CRow>
              <CCol lg="5">
                通知の送付先
              </CCol>
              <CCol lg="7">
                <CInputCheckbox
                  label="管理者のメール"
                  checked
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="登録されてるすべてのメンバのメール"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="LINE Notify"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" lg="5" style="font-weight: lighter">
            <div>ユーザが設定自由に設定できる項目を指定します。</div>
            <div>チェックがついてる項目はユーザによる変更が可能になります。</div>
          </CCol>
        </CRow>
        <CRow style="margin-bottom: 16px">
          <CCol lg="7">
            <CRow>
              <CCol lg="5">
                通知を送付タイミング
              </CCol>
              <CCol lg="7">
                <CInputCheckbox
                  label="新しいお仕事が登録されたとき"
                  checked
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="お仕事が完了したとき"
                />
              </CCol>
              <CCol lg="7" class="offset-lg-5">
                <CInputCheckbox
                  label="募集中のお仕事の募集期間が終わる前"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" lg="5" style="font-weight: lighter">
            <div>ユーザが設定自由に設定できる項目を指定します。</div>
            <div>チェックがついてる項目はユーザによる変更が可能になります。</div>
          </CCol>
        </CRow>

      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              @click="updateFamilyProfileSettings"
              style="width:100%"
              variant="outline"
              size="sm"
              color="primary"
            >更新
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>    </CCard>
    <!-- 開発者向け設定のカード -->
    <CCard v-if="$store.state.user_id === 1">
      <CCardBody>
        <CRow>
          <CButton @click="showStore">ストア</CButton>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- トースター -->
    <CToaster
      v-for="(toaster, toasterKey) in toasters"
      :position="toasterKey"
      :key="'toaster' + toasterKey"
    >
      <template v-for="(toast, key) in toaster">
        <CToast
          :key="'toast' + key"
          :show="true"
          v-bind="toast"
        >
          {{toast['msg']}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: "Config",
  mixins: [Comm],/* ここでミックスインを追加 */
  components: {},
  props: {
  },
  mounted() {
    this.initShow()
    this.jobCategories = this.$store.state.job_categories
  },
  watch: {},
  computed: {
    isActive: function() {
      return function(num){
        return num === 0
      }
    }
  },
  data() {
    return {
      show: [],
      navs: ["お仕事設定", "プロフィール", "ユーザ設定", "通知設定"],
      showEditMember: false, //メンバ追加 or 編集フォームの表示非表示
      registerButtonLabel: '',
      //お仕事設定タブの設定
      jobSettings: {
        numOfListingJobs: 7,
        numOfParallelJobs: 2,
        defaultJobPrice: 1500,
        lineAPIKey: '',
      },
      userPermitSettings: {
        changeUsername: true,
        changePassword: true,
        changeAvatar: true,
        changeEmail: true,
        changeNickname: true,
        changeFirstName: true,
        changeLastName: true
      },
      jobCategories: [],
      //プロフィールタブの設定
      familyProfiles: {
        nickname: '',
        birthYear: '',
        familyStructure: '',
        postalCode: '',
        housingForm: '',
        occupation: '',
        annualIncome: ''
      },
      //プロフィールタブのセレクトオプション
      familyStructureOptions: [
        {value: 0, label: '--選択してください--'},
        {value: 1, label: '独身'},
        {value: 2, label: '既婚(子供なし)'},
        {value: 3, label: '既婚(子供一人)'},
        {value: 4, label: '既婚(子供二人)'},
        {value: 5, label: '既婚(子供三人以上)'},
        {value: 6, label: '親と同居'},
        {value: 7, label: 'その他'},
      ],
      housingFormOptions: [
        {value: 0, label: '--選択してください--'},
        {value: 1, label: '一戸建て(持ち家)'},
        {value: 2, label: '一戸建て(借家)'},
        {value: 3, label: '集合住宅(分譲)'},
        {value: 4, label: '集合住宅(賃貸)'},
        {value: 5, label: '社宅・寮'},
        {value: 6, label: '家族と同居'},
        {value: 7, label: '旅人・ノマド'},
        {value: 8, label: 'その他'},
      ],
      occupationOptions: [
        {value: 0, label: '--選択してください--'},
        {value: 1, label: '無職'},
        {value: 2, label: '主婦・主夫'},
        {value: 3, label: '投資家'},
        {value: 4, label: 'IT・通信'},
        {value: 5, label: '製造業（機械・自動車・電機）'},
        {value: 6, label: '製造業(素材・医薬品他)'},
        {value: 7, label: '資源・貿易・商社'},
        {value: 8, label: '流通・小売'},
        {value: 9, label: '飲食・サービス業'},
        {value: 10, label: 'クリエイター'},
        {value: 11, label: 'コンサルティング'},
        {value: 12, label: 'マスコミ・出版'},
        {value: 13, label: '金融業'},
        {value: 14, label: '不動産・建設'},
        {value: 15, label: '教育'},
        {value: 16, label: '公務員・団体職員'},
        {value: 17, label: '専門職'},
        {value: 18, label: '自営業'},
        {value: 19, label: '学生'},
        {value: 20, label: 'その他'},
      ],
      annualIncomeOptions: [
        {value: 0, label: '--選択してください--'},
        {value: 1, label: '0万円~200万円'},
        {value: 2, label: '200万円~400万円'},
        {value: 3, label: '400万円~600万円'},
        {value: 4, label: '600万円~800万円'},
        {value: 5, label: '800万円~1,000万円'},
        {value: 6, label: '1,000万円~1,500万円'},
        {value: 7, label: '1,500万円~2,000万円'},
        {value: 8, label: '2,000万円~3,000万円'},
        {value: 9, label: '3,000万円~'},
      ],
      //ユーザ追加 or 編集の値
      editMember: {
        first_name: '',
        is_admin: false,
        last_name: '',
        mail: '',
        nickname: '',
        username: ''
      }
    }
  },
  methods: {
    async updateNotificationSettings(){
      const item_json = {
        notification_settings: JSON.stringify()
      }
      const response = await this._update_settings(item_json)
      console.log(response)
    },
    async updateFamilyProfileSettings(){
      const item_json = {
        family_profile_settings: JSON.stringify(this.familyProfiles)
      }
      const response = await this._update_settings(item_json)
      console.log(response)
    },
    async updateJobSettings(){
      const item_json = {
        job_settings: JSON.stringify({
          job_settings: this.jobSettings,
          user_permit_settings: this.userPermitSettings
        })
      }
      const response = await this._update_settings(item_json)
      console.log(response)
    },
    //トーストを表示するメソッド
    saveValue(value, type){
      console.log("hoge")
      this.addToast("表示件数設定", "表示件数を「ほげ」に設定しました。")
    },
    addJobCategory(value){
      this.jobCategories.push({
        default: false,
        label: value,
        type: 1,
      })
    },
    deleteJobCategory(index){
      let msg = `お仕事カテゴリ「${this.jobCategories[index].label}」を削除しますか？\n`
      msg = msg + `「${this.jobCategories[index].label}」に紐付いてるお仕事は「その他」カテゴリになります。`
      const ans = window.confirm(msg)
      if(ans){
        this.jobCategories.splice(index, 1)
      }
    },
    setDefaultCategory(index){
      this.jobCategories.map((job, _index) => {
        if(index === _index){
          job['default'] = true
        }else{
          job['default'] = false
        }
      }, this)
    },
    isDefaultCategory(item){
      if(item['default'] === true){
        return 'color:lightgreen'
      }else{
        return 'color:dimgrey'
      }
    },
    validatorUserId(val){
      console.log(val)
      return val ? val.length >= 4 : false
    },
    addMember(){
      this.registerButtonLabel = 'ユーザを追加'
      this.showEditMember = true
    },
    clickMember(member){
      Object.keys(member).map(key => {
        this.editMember[key] = member[key]
      }, this)
      // this.editMember = member
      this.registerButtonLabel = 'ユーザ情報を修正'
      this.showEditMember = true
    },
    cancelEditMember(){
      this.editMember = {
        first_name: '',
        is_admin: false,
        last_name: '',
        mail: '',
        nickname: '',
        username: ''
      }
      this.registerButtonLabel = ''
      this.showEditMember = false
    },
    showStore(){
      console.log(this.$store.state)
    },
    initShow: function(){
      for(let i = 0; i < this.navs.length; i++){
        this.show.push(false)
      }
      this.show.splice(0, 1, true)
    },
    isShow(num) {
      this.show.forEach((item, index) => {
        this.show.splice(index, 1, false)
      }, this)
      this.show.splice(num, 1, true)
    }
  }
}
</script>

<style scoped>
</style>
